import { __decorate } from "tslib";
import { Vue, Prop, Component, Watch, } from 'vue-property-decorator';
let SearchBar = class SearchBar extends Vue {
    constructor() {
        super(...arguments);
        this.innerValue = '';
    }
    mounted() {
        if (this.focus) {
            const el = this.$refs.input;
            el.focus();
        }
    }
    extraOprate() {
        this.$emit('extraOp');
    }
    debounce(fn, wait) {
        let timeout = null;
        return function () {
            if (timeout !== null)
                clearTimeout(timeout);
            timeout = setTimeout(fn, wait);
        };
    }
    handleInput(evt) {
        if (evt.target._composing) {
            return;
        }
        this.$emit('change', evt.target.value);
        this.$emit('input', evt.target.value);
    }
    valueChange(v) {
        this.innerValue = v;
    }
};
__decorate([
    Prop()
], SearchBar.prototype, "value", void 0);
__decorate([
    Prop({ default: '取消' })
], SearchBar.prototype, "extraBtn", void 0);
__decorate([
    Prop()
], SearchBar.prototype, "maxlength", void 0);
__decorate([
    Prop({ default: false })
], SearchBar.prototype, "focus", void 0);
__decorate([
    Watch('value')
], SearchBar.prototype, "valueChange", null);
SearchBar = __decorate([
    Component({
        name: 'search-bar',
    })
], SearchBar);
export default SearchBar;
